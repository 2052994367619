<template>
  <v-sheet class="wsRoundedHalf pa-6 mt-6">
    <h3 class="font-weight-medium">{{  $t('Orders')  }}</h3>

    <v-fade-transition mode="out-in">

      <v-data-table
          v-if="items.length > 0"
          class="mt-6"
          :items="items"
          :headers="headers" >

        <template v-slot:item="{item}">
          <tr>
            <td width="10px" class="text-no-wrap">
              <wsChip icon="mdi-calendar" :text="PARSE_TIME_STAMP(item.date, true)" />
            </td>
            <td>
              <h5 class="font-weight-medium"> {{ item.order_id }}</h5>
            </td>
            <td>
              <div v-if="item.products.length === 1" >
                <wsChip :text="item.products[0].name" :color="wsACCENT" icon="mdi-school" />
              </div>
            </td>
            <td width="10px">
              <div class="d-flex">
                <v-icon small color="grey" >mdi-cash</v-icon>
                <h5 class="text-no-wrap">{{ item.price }} {{ BUSINESS_CURRENCY }}</h5>
              </div>
            </td>
            <td align="center" class="px-0" width="10px" >
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip :color="item.payed ? wsSUCCESS : 'grey' "  dark small outlined  v-bind="attrs" v-on="on">
                    <h4> {{ item.payed ? $t('PaymentConfirmed') : $t('PaymentNotConfirmed') }} </h4>
                  </v-chip>
                </template>
                <v-list dense dark>
                  <v-list-item-group >
                    <v-list-item   @click="openConfirmation('payment_status',item, true)">
                      <v-list-item-title>Оплачено</v-list-item-title>
                    </v-list-item>
                    <v-list-item   @click="openConfirmation('payment_status',item, 'false')">
                      <v-list-item-title>Не оплачено</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>

      </v-data-table>

      <v-sheet v-else :color="wsACCENT" class="wsRoundedHalf pa-6 py-16 mt-6" dark>
        <h5 class="font-weight-light text-center"> {{ $t('ClientHasNoOrdersYet') }}</h5>
      </v-sheet>

    </v-fade-transition>


  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "clientOrders",
  props : {
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      items : []
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Date')        , value : 'date' },
        { text : this.$t('OrderNumber') , value : 'order_id' , },
        { text : this.$t('CourseName')  , value : 'products' ,  sortable : false },
        { text : this.$t('Price')       , value : 'price'    , align : 'center' },
        { text : this.$t('Payment')     , value : 'payed'    , align : 'center' }
      ]
    }
  },
  methods : {
    ...mapActions( 'crmSystem', [
      'GET_CLIENT_ORDERS' , ]),

    async initPage() {
      let result = await this.GET_CLIENT_ORDERS(this.uuid)
      if ( !result || result === true ) {
        return
      }
      this.items = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>